<template>
    <div>
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <div class="container">
            <!-- 新增 编辑 -->
            <el-form ref="form" v-bind:model="form" label-width="110px" size="large" v-bind:rules="rules" label-suffix="：" class="dialog_box">
                <p class="p-title">基础信息</p>
                <el-form-item label="标题/描述" prop="title">
                    <el-input class="form-input" v-model="form.title" placeholder="请输入标题/描述" style="margin-bottom: 20px;"></el-input>
                </el-form-item>
                <el-form-item label="内容" prop="content">
                    <el-input class="form-input" v-model="form.content" placeholder="请输入内容" style="margin-bottom: 20px;"></el-input>
                </el-form-item>
                <el-form-item label="头像" prop="avatar">
                    <el-input v-model="form.avatar" class="form-input" placeholder="请输入图片链接" style="margin-bottom: 20px;"></el-input>
                    <el-button @click.prevent="choiceImg('avatar')">选择图片</el-button>
                    <el-upload :class="[form.avatar ?'avatar-uploader1':'avatar-uploader','img1','up']" :action="IMAGE_UPLOAD_PRO_URL" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" name="file" :headers="header">
                        <img v-if="form.avatar" :src="form.avatar" class="avatar" />
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="封面图" prop="thumb">
                    <el-input class="form-input" v-model="form.thumb" placeholder="请输入封面图链接" style="margin-bottom: 20px;"></el-input>
                    <el-button @click.prevent="choiceImg('thumb')">选择图片</el-button>
                    <el-upload :class="[form.thumb ?'avatar-uploader1':'avatar-uploader','img2','up']" :action="IMAGE_UPLOAD_PRO_URL" :show-file-list="false" :on-success="handleThumbSuccess" :before-upload="beforeThumbUpload" name="file" :headers="header">
                        <img v-if="form.thumb" :src="form.thumb" class="thumb" />
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="发布者" prop="username">
                    <el-input class="form-input" v-model="form.username" placeholder="请输入发布者" style="margin-bottom: 20px;"></el-input>
                </el-form-item>
                <!-- <el-form-item label="来源" prop="platform">
                    <el-select v-model="form.platform" placeholder="平台" class="handle-select mr10 form-input">
                        <el-option v-for="items in platformList" :key="items.ty" :label="items.name" :value="items.ty">
                        </el-option>
                    </el-select>
                </el-form-item>-->
                <el-form-item label="视频上传" prop="Video">
                    <!-- action必选参数, 上传的地址 -->
                    <el-input class="form-input" v-model="form.Video" placeholder="请输入视频链接" style="margin-bottom: 20px;"></el-input>
                    <el-upload :class="[form.Video ?'avatar-uploader1':'avatar-uploader','el-upload--text','up']" action="/i/admin/system/upload/video" :headers="header" :show-file-list="false" :on-success="handleVideoSuccess" :before-upload="beforeUploadVideo" :on-progress="uploadVideoProcess">
                        <video v-if="form.Video !='' && videoFlag == false" :src="form.Video" class="video" controls="controls">您的浏览器不支持视频播放</video>
                        <i v-if="form.Video =='' && videoFlag == false" class="el-icon-plus avatar-uploader-icon"></i>
                        <el-progress v-if="videoFlag === true" type="circle" :percentage="videoUploadPercent" style="margin-top:30px;"></el-progress>
                    </el-upload>
                </el-form-item>
                <el-form-item label="话题" prop="column_id">
                    <el-select class="form-input handle-select mr10" v-model="form.column_id" placeholder="请选择话题" style="margin-bottom: 20px;">
                        <el-option v-for="item in topicList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <p class="p-title">关联tag</p>
                <el-form-item prop="tag">
                    <el-tag v-for="(item,index) in tagList" :key="item.index" closable :disable-transitions="true" @close="handleClose(index)">{{item.name}}</el-tag>
                    <el-autocomplete ref="saveTagInput" v-if="tagVisible" class="inline-input" v-model="tag" :fetch-suggestions="queryTagSearch" placeholder="输入内容" :trigger-on-focus="false" @select="handleTagSelect"></el-autocomplete>
                    <el-button v-else class="button-new-tag" size="small" @click="showtag">添加标签</el-button>
                </el-form-item>
                <p class="p-title">关联商品</p>
                <el-form-item prop="tag">
                    <el-button type="warning" @click="addProduct" style="margin-bottom:10px">选择商品</el-button>
                    <el-table v-loading="loading" element-loading-text="数据加载中" stripe border :data="goodsList" style="width: 60%">
                        <el-table-column label="商品名称" width="300" prop="title" align="center"></el-table-column>
                        <el-table-column label="价格" width="100" prop="productprice" align="center"></el-table-column>
                        <el-table-column label="品牌" prop="brand.name" align="center"></el-table-column>/
                        <el-table-column label="商品库存" width="100" prop="stock" align="center"></el-table-column>
                        <el-table-column label="操作" align="center">
                            <template slot-scope="scope">
                                <el-button style="margin-left: 0px;" size="small" type="primary" plain @click="deleteProduct(scope.$index)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
                <p class="p-title">显示设置</p>
                <el-form-item label="资源类型" prop="is_video">
                    <el-radio-group v-model="form.is_video">
                        <el-radio :label="1">视频</el-radio>
                        <el-radio :label="0">文章</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="是否置顶" prop="is_top">
                    <el-radio-group v-model="form.is_top">
                        <el-radio :label="2">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="是否加精" prop="is_tperfect">
                    <el-radio-group v-model="form.is_tperfect">
                        <el-radio :label="2">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="saveData">确 定</el-button>
            </div>
            <Addproduct :addVisible="addVisible" :title="title" :goodsList="goodsList" @toggleAdd="toggleAdd" @getAddGood="getAddGood"></Addproduct>
        </div>
        <!-- 选择图片对话框 -->
        <div class="img">
            <Picture :ImgVisible="ImgVisible" @toggleImg="toggleImg" @getImg="getImg"></Picture>
        </div>
    </div>
</template>
<script>
import bus from '@/common/bus';
import loadEvents from '@/utils/loading'
import Breadcrumb from '@/components/Breadcrumb';
import Addproduct from '@/components/Addproduct';
import Picture from '@/components/Picture';
import {
    request
} from '@/common/request';
import {
    deal
} from '@/common/main';
import {IMAGE_UPLOAD_PRO_URL} from "../../common/const";

let loads = new loadEvents();
export default {
    name: 'ArticleAdd',
    components: {
        Breadcrumb,
        Addproduct,
        Picture
    },
    data() {
        return {
            IMAGE_UPLOAD_PRO_URL:IMAGE_UPLOAD_PRO_URL,
            ImgVisible: false,
            text: 'image',
            menuList: ['资源管理', '新增资源'],
            tag: '',
            state: '',
            loading: false,
            title: "选择商品",
            rules: {
                title: [{
                    required: true,
                    message: '标题/描述不能为空',
                    trigger: 'blur'
                }],
                content: [{
                    required: true,
                    message: '内容不能为空',
                    trigger: 'blur'
                }],
                avatar: [
                    { validator: deal.checkImage, trigger: ['blur', 'change'], required: true, }
                ],
                thumb: [
                    { validator: deal.checkImage, trigger: ['blur', 'change'], required: true, }
                ],
                Video: [
                    { validator: deal.checkVideo, trigger: ['blur', 'change'] }
                ],
                username: [{
                    required: true,
                    message: '发布者不能为空',
                    trigger: 'blur'
                }],
                column_id: [{
                    required: true,
                    message: '话题不能为空',
                    trigger: 'blur'
                }],
                is_top: [{
                    required: true,
                    message: '是否置顶不能为空',
                    trigger: 'blur'
                }],
                is_tperfect: [{
                    required: true,
                    message: '是否加精不能为空',
                    trigger: 'blur'
                }],
                is_video: [{
                    required: true,
                    message: '资源类型不能为空',
                    trigger: 'blur'
                }],
            },
            queryParams: {
                state: 2,
                page: 1,
                pagesize: -1
            },
            id: null,
            value: '',
            addVisible: false,
            form: {
                title: '',
                avatar: '',
                thumb: '',
                Video: '',
                is_top: 2,
                is_tperfect: 2,
                state: 2,
                is_video: 0
            },
            tagVisible: false,
            goodsVisible: false,
            tagList: [],
            videoFlag: false,
            topicList: [],
            searchGoodsList: [],
            goodsList: [],
            header: {},

            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;
                }
            },
            videoUploadPercent: 0,
            selectProductList: [],
            queryProductParams: {
                'name': '',
                page: 1,
                pagesize: 10
            },
            platformList: [],
        }
    },
    created() {
        this.header.token = localStorage.getItem('token');
        this.header.platform = 'admin';
        // 获取话题数据
        this.getTopic()
    },


    methods: {
        toggleImg(val) { // 关闭 img对话框
            this.ImgVisible = val;

        },
        choiceImg(text) {
            this.text = text
            this.ImgVisible = true
        },
        getImg(url) {// 获取选择的img
            this.$set(this.form, this.text, url);
        },
        // 关闭tag
        handleClose(index) {
            this.tagList.splice(index, 1);
            this.state = ''
        },
        showtag() {
            this.tagVisible = true;
        },


        // 添加商品
        addProduct() {
            this.addVisible = true;
        },

        // 已选商品删除
        deleteProduct(index) {
            this.goodsList.splice(index, 1)
        },
        queryTagSearch(queryString, cb) {
            let results = []
            this.queryParams.name = queryString
            request.get('/common/tag/list', this.queryParams).then(ret => {
                if (ret.code == 1) {
                    if (ret.data.list.length > 0) {
                        setTimeout(() => {
                            for (let i of ret.data.list) {
                                i.value = i.name;  //将想要展示的数据作为value
                            }
                            results = ret.data.list
                            cb(results);
                        }, 200);
                    } else {
                        request.post('/common/tag/add', { name: queryString, state: 2 }).then(ret => {
                            if (ret.code == 1) {
                                this.queryTagSearch(queryString, cb);
                            } else {
                                this.$message.error(ret.msg);
                            }
                        });
                    }

                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 新增tag
        handleTagSelect(item) {
            this.tagVisible = false;
            this.tagList.push(item)
            let obj = {};
            let newArr = this.tagList.reduce((cur, next) => {
                obj[next.id] ? "" : obj[next.id] = true && cur.push(next);
                return cur;
            }, [])
            this.tagList = newArr
            this.tag = null
        },
        // 视频上传
        handleVideoSuccess(res, file) {                               //获取上传图片地址
            this.videoFlag = false;
            this.videoUploadPercent = 0;
            if (res.code == 1) {
                this.form.Video = res.data.uri;
            } else {
                this.$message.error('视频上传失败，请重新上传！');
            }
        },
        beforeUploadVideo(file) {
            const isLt10M = file.size / 1024 / 1024 < 100;
            if (['video/mp4', 'video/ogg', 'video/flv', 'video/avi', 'video/wmv', 'video/rmvb'].indexOf(file.type) == -1) {
                this.$message.error('请上传正确的视频格式');
                return false;
            }
            if (!isLt10M) {
                this.$message.error('上传视频大小不能超过100MB哦!');
                return false;
            }
        },

        uploadVideoProcess(event, file, fileList) {
            this.videoFlag = true;
            if (file && file.percentage) {
                this.videoUploadPercent = parseInt(file.percentage);
            }

        },
        // 头像上传
        handleAvatarSuccess(res, file) {
            if (res.code == 1) {
                loads.close()
                this.form.avatar = res.data.uri
                // this.$set(this.form, 'avatar', res.data.uri);
            }
        },
        beforeAvatarUpload(file) {
            loads.open(`.img1 .el-upload`, '上传中')
            const _this = this
            return deal.imageFormat(file, _this)
        },
        // 封面图上传
        handleThumbSuccess(res, file) {

            if (res.code == 1) {
                loads.close()
                this.form.thumb = res.data.uri
                // this.$set(this.form, 'thumb', res.data.uri);
            }
        },
        beforeThumbUpload(file) {
            loads.open(`.img2 .el-upload`, '上传中')
            const _this = this
            return deal.imageFormat(file, _this)
        },
        // 获取话题数据
        getTopic() {
            request.get('/community/column/list', { page: 1, pagesize: -1 }).then(ret => {
                if (ret.code == 1) {
                    this.topicList = ret.data.list;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 保存数据
        saveData() {
            if (this.$refs.form) {
                this.$refs.form.validate(valid => {
                    if (valid) {
                        let param = Object.assign({}, this.form);
                        let tagId = this.tagList.map(item => item.id)
                        let goodsId = this.goodsList.map(item => item.id)
                        let goodsIds = goodsId.join(',')
                        let tagIds = tagId.join(',')
                        this.$set(param, 'tag_ids', tagIds)
                        this.$set(param, 'goods_ids', goodsIds)
                        var url = '/community/add';
                        var list = [];
                        var obj = {};
                        obj.content = this.form.Video;
                        list.push(obj);
                        param.list = JSON.stringify(list);
                        console.log(333333, param)
                        //处理时间
                        request.post(url, param).then(ret => {
                            if (ret.code == 1) {
                                this.$message.success('编辑成功');
                                this.imageUrl = '';
                                this.$refs.form.resetFields()
                                bus.$emit('close_current_tags', '/community-ArticleAdd');
                                this.$router.push({ path: '/community-Article' })
                            } else {
                                this.$message.error(ret.msg);
                            }
                        });

                    } else {
                        this.$refs.form.resetFields()
                        this.$message.error('请补全信息');
                        return false;
                    }
                });
            };
        },
        toggleAdd(val) {
            this.addVisible = val
        },
        getAddGood(val) {
            this.goodsList = val
        }
    },
}
</script>
<style scoped>
.head_img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 4px;
}

.pagination {
    text-align: right;
    margin-top: 10px;
}

.p-title {
    font-size: 18px;
    color: #303133;
    line-height: 24px;
    padding-top: 20px;
    padding-bottom: 10px;
    font-weight: 600;
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader1 .el-upload {
    border: 0;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
}

.avatar {
    width: 100px;
    height: 100px;
}

.thumb {
    width: 200px;
    height: 300px;
}

.video {
    width: 300px;
    height: 200px;
}

.avatar-uploader .avatar-uploader-icon {
    line-height: 100px;
}

.cell {
    text-align: center;
}

.el-input--suffix .el-input__inner {
    padding-right: 15px;
}

.up {
    margin-top: 20px;
}

.el-tag + .el-tag {
    margin-left: 10px;
}

.button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
}

.input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
}

.container {
    background: #ffffff;
    /* display: flex;
      flex-direction: column;
      align-items: center; */
}

.form-input >>> .el-input__inner {
    width: 500px;
}

.el-input--small .el-input__inner {
    width: 500px;
}
/* 选择链接样式 */
.dialog_box .el-button {
    margin-left: 20px;
}
</style>
